<template>
  <v-container justify="center" class="contentContainer">
    <PageHeader heading="My Dashboard" class="mb-4"/>
    <v-row justify="center" class="ma-0">
      <v-col class="contentMaxWidth pa-0">
        <DashboardOverview />
      </v-col>
    </v-row>
    <v-row class="mt-0 " justify="center">
      <v-col class="pt-0 contentMaxWidth">
        <v-tabs v-model="tab" color="accent" :grow="store.app.onMobile" background-color="background" style="margin: 0 -12px" slider-size="3">
          <v-tab class="text-capitalize" data-cy="dashboard_tab_overview">Overview</v-tab>
          <v-tab class="text-capitalize" data-cy="dashboard_tab_timetables">Timetables</v-tab>
          <v-tab class="text-capitalize" data-cy="dashboard_tab_reviews">Reviews</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row class="mt-0"><v-divider/></v-row>
    <v-row justify="center">
      <v-col class="contentMaxWidth pt-1 px-0">
        <v-tabs-items v-model="tab">
          <v-tab-item class="pa-3" style="background-color: var(--v-background-base)">
            <v-row justify="center" class="px-0" style="min-width: 100%">
              <v-col ref="dashboard_left_panel" class="pl-0 col-md-9 pr-md-8" cols="12">
                <v-alert v-if="!store.user.userInfo.verified" border="left" text type="warning"
                         icon="mdi-alert-outline" class="py-3 mb-4 d-flex">
                  <span class="mb-0"><strong>Please note</strong> in order to add course reviews and
                    save timetables you will need to verify the email associated with your UofT Index account.
                    <strong @click="verifyMe" class="hover accent--text">Verify my account</strong>
                  </span>
                </v-alert>
                <PinnedTimetables @update="updateSchedules = false" :reload="updateSchedules"/>
                <MyCourses class="mt-8 mt-md-5" :add-course="addCourses"/>
              </v-col>
              <v-col class="px-0 pl-md-3 col-md-3 d-none d-md-block">
                <ProfileAchievements/>
                <v-divider class="my-6"/>
                <ProfileCourseDemographics/>
              </v-col>
            </v-row>

          </v-tab-item>
          <v-tab-item style="background-color: var(--v-background-base)">
            <MySchedules @update="updateSchedules = true" class="w-full mt-2" style="height: 100%"/>
          </v-tab-item>
          <v-tab-item style="background-color: var(--v-background-base)">
            <UserReviews/>
          </v-tab-item>
        </v-tabs-items>

        <OnboardingModal v-if="store.app.welcomeModal" @addCourses="addCourses = true"/>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MyCourses from '@/components/dashboard/MyCourses.vue'
import { useAllStores } from '@/stores/useAllStores'
import MySchedules from '@/components/dashboard/MySchedules.vue'
import OnboardingModal from '@/components/OnboardingModal'
import DashboardOverview from '@/components/dashboard/DashboardOverview.vue'
import ProfileCourseDemographics from '@/components/dashboard/ProfileCourseDemographics.vue'
import PageHeader from '@/components/PageHeader.vue'
import UserReviews from '@/components/dashboard/UserReviews.vue'
import ProfileAchievements from '@/components/dashboard/ProfileAchievements.vue'
import PinnedTimetables from '@/components/dashboard/PinnedTimetables.vue'

export default {
  name: 'Dashboard',
  components: {
    PinnedTimetables,
    ProfileAchievements,
    UserReviews,
    PageHeader,
    ProfileCourseDemographics,
    DashboardOverview,
    MyCourses,
    MySchedules,
    OnboardingModal
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    tab: null,
    prevToastID: null,
    addCourses: false,
    updateSchedules: false
  }),
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.store.user.userInfo) {
        next('/session')
      } else {
        vm.store.data.loadUserCourses()
      }
    })
  },
  methods: {
    verifyMe () {
      this.store.app.verifyMe()
        .then(message => this.$toast.info(message, { timeout: 10000 }))
        .catch(err => this.$toast.error(err.message))
    }
  }
}
</script>

<style scoped>
  >>>.apexcharts-svg:hover {
    cursor: pointer;
  }
  >>>.v-toolbar__content {
    padding-right: 0;
  }
  .v-application a {
    font-weight: 520;
    text-decoration: none;
    color: #003C85 !important;
  }
  .courseTableRow {
    min-width: 100%;
  }

</style>
