<template>
  <v-container class="pa-0 mx-0" style="width: auto;">
    <span v-if="!editMode">
      <v-btn v-if="store.user.userInfo" class="px-2 px-sm-3" depressed :color="!course.rated ? 'accent' : 'success'"
             @click="reviewModal = !course.rated" :outlined="course.rated">
        <span v-if="!course.rated"><v-icon class="mr-3">mdi-star-plus</v-icon>Add Review</span>
        <span v-else><v-icon class="mr-2" color="success">mdi-checkbox-marked-circle-outline</v-icon>Already Reviewed!</span>
      </v-btn>
      <v-btn v-else class="px-2 px-sm-3" depressed color="accent" @click="needAuthentication = true">
        <v-icon class="mr-3">mdi-star-plus</v-icon>Add Review
    </v-btn>
    </span>
    <v-dialog v-model="reviewModal" class="overflow-y-hidden" fullscreen persistent transition="dialog-bottom-transition">
      <v-icon class="closeReview" color="#FFFFFF" @click="resetModal">mdi-close</v-icon>
      <v-card id="reviewModal" :outlined="store.app.darkMode" height="calc(100vh - 50px)" class="reviewCard">
        <v-card-text class="pb-0 px-0">
          <v-row class="mt-5" justify="center">
            <v-col class="contentMaxWidth pb-0">
              <v-alert v-if="store.user.userInfo && !store.user.userInfo.verified" border="left" text type="warning"
                       icon="mdi-alert-outline" class="mx-0 mb-6" style="margin: 0 -12px 20px -12px !important;">
                <span class="mb-0 text-body-1">Please verify the email associated with your UofT Index account before
                  trying to rate a course.
                  <strong class="hover accent--text" @click="verifyMe">Verify my account</strong>
                </span>
              </v-alert>
              <v-row class="pt-6 pb-5 pb-md-10 align-center">
                <div>
                  <v-row class="ma-0 align-center">
                    <p class="mb-0 text-break font-weight-bold text-h4">{{ course.code }}</p>
                    <QuickStats v-if="!store.app.onMobile" :stats="course" css="ml-5" icon-size="26"/>
                  </v-row>
                  <p class="font-weight-regular text-body-1 mb-0">{{ course.course.slice(10) }}</p>
                </div>
                <v-spacer/>
                <p v-if="$vuetify.breakpoint.mdAndUp" class="text--secondary mb-0">
                  <strong class="error--text mx-1">*</strong> indicates a mandatory field
                </p>
              </v-row>
              <v-row>
                <v-col md="3" cols="12" class="d-none d-md-block px-0 pb-0">
                  <h2 class="text--text mb-3">Overall</h2>
                </v-col>
                <v-col md="9" cols="12" class="px-0">
                  <v-row class="ma-0 pb-6">
                    <v-col lg="3" md="3" sm="3" cols="12" class="pa-0 d-flex align-center">
                      <h3 class="text--text text-capitalize">Rating<strong class="error--text ml-1">*</strong></h3>
                    </v-col>
                    <v-col lg="6" cols="12" class="pa-0">
                      <v-rating v-model="courseRating.rating" background-color="grey" color="warning"
                                hover x-large length="5" class="text-center ml-md-4"/>
                    </v-col>
                  </v-row>
                  <div class="pb-4">
                    <h3 class="text--text text-capitalize">Recommend<strong class="error--text ml-1">*</strong></h3>
                    <p class="text--secondary mb-2">A value of 4 and above is considered a recommendation</p>
                    <v-row class="my-4 mx-1 mx-md-6 align-center" justify="space-around">
                      <p v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 text--secondary mb-0 d-flex align-center">Very unlikely</p>
                      <v-chip-group v-model="courseRating.recommend" active-class="activeRating" class="mx-4">
                        <v-chip v-for="value in 5" :key="value" label style="height: 45px" color="border" class="text-body-1 mx-3 px-4 px-lg-8">
                          {{ value }}
                        </v-chip>
                      </v-chip-group>
                      <p v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 text--secondary mb-0 d-flex align-center">Very likely</p>
                    </v-row>
                  </div>
                  <div class="pb-4">
                    <h3 class="text--text text-capitalize">Workload<strong class="error--text ml-1">*</strong></h3>
                    <v-row class="my-4 mx-1 mx-md-6 align-center" justify="space-around">
                      <p v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 text--secondary mb-0 mr-7 d-flex align-center">Very light</p>
                      <v-chip-group v-model="courseRating.workload" active-class="activeRating" class="mx-4">
                        <v-chip v-for="value in 5" :key="value" label style="height: 45px" color="border" class="text-body-1 mx-3 px-4 px-lg-8">
                          {{ value }}
                        </v-chip>
                      </v-chip-group>
                      <p v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 text--secondary mb-0 d-flex align-center">Very heavy</p>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-row><v-divider class="mt-0 mb-8"/></v-row>
              <v-row>
                <v-col md="3" cols="12" class="px-0 pb-0">
                  <h2 class="text--text mb-3">Course Evaluation</h2>
                </v-col>
                <v-col md="9" cols="12" class="px-0">
                  <div v-for="(rating, idx) in ratingFields" :key="idx" class="pb-4 pb-sm-8">
                    <h3 class="text--text text-capitalize">{{ rating.label }}<strong class="error--text ml-1">*</strong></h3>
                    <p class="text--secondary mb-2">{{ rating.text }}</p>
                    <v-row class="my-4 mx-1 mx-md-6 align-center" justify="space-around">
                      <p v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 text--secondary mb-0 d-flex align-center">Strongly Disagree</p>
                      <v-chip-group v-model="courseRating[rating.label]" active-class="activeRating" class="mx-4">
                        <v-chip v-for="value in 5" :key="value" label style="height: 45px" color="border" class="text-body-1 mx-3 px-4 px-lg-8">
                          {{ value }}
                        </v-chip>
                      </v-chip-group>
                      <p v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 text--secondary mb-0 d-flex align-center">Strongly Agree</p>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-row><v-divider class="mt-0 mb-8"/></v-row>
              <!-- UPDATE - January 18, 2023 -->
              <v-row>
                <v-col md="3" cols="12" class="px-0 pb-0">
                  <h2 class="text--text mb-3">Session Details</h2>
                </v-col>
                <v-col md="9" cols="12" class="px-0">
                  <v-row>
                    <v-col cols="12" md="3">
                      <p class="mb-1">Year</p>
                      <v-autocomplete v-model="courseRating.year" height="45" dense color="accent"
                                      hide-details outlined placeholder="Select a year"
                                      :items="Array.from({length: 10}, (_, i) => new Date().getFullYear() - i)"/>
                    </v-col>
                    <v-col cols="12" md="3">
                      <p class="mb-1">Semester</p>
                      <v-autocomplete v-model="courseRating.sem" height="45" dense color="accent"
                                      hide-details outlined placeholder="Select a semester"
                                      :items="['Fall', 'Winter', 'Summer']" />
                    </v-col>
                    <v-col cols="12" md="3">
                      <p class="mb-1">Term</p>
                      <v-autocomplete v-model="courseRating.term" height="45" dense color="accent"
                                      hide-details outlined placeholder="Select a term" :items="terms" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <p class="mb-1">Professor</p>
                      <v-autocomplete v-model="courseRating.professor" height="45" dense color="accent"
                                      hide-details outlined placeholder="Select a professor" item-text="name" item-value="_id"
                                      :items="store.data.ratingOptions.professors"/>
                    </v-col>
                    <v-col cols="12" md="3">
                      <p class="mb-1">Grade</p>
                      <v-autocomplete v-model="courseRating.grade" height="45" dense color="accent"
                                      hide-details outlined  placeholder="Select a grade"
                                      :items="grades"/>
                    </v-col>
                    <v-col cols="12" md="3" class="pl-4 pl-md-6">
                      <p class="mb-0">Bird Course</p>
                      <v-switch v-model="courseRating.bird" inset color="accent" class="mx-1 mt-3"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row><v-divider class="mt-4 mt-md-8 mb-8"/></v-row>
              <!-- UPDATE - December 12, 2022 -->
              <v-row>
                <v-col md="3" cols="12" class="px-0 pb-0">
                  <h2 class="text--text mb-1">Course Tags</h2>
                  <p class="text--secondary mb-2">Maximum of 5 tags/review</p>
                </v-col>
                <v-col md="9" cols="12" class="px-0">
                  <v-chip-group v-model="courseRating.tags" multiple max="5" active-class="accent--text font-weight-medium"
                                data-cy="filter_section" column>
                    <v-chip v-for="(tag, idx) in store.data.ratingOptions.tags" :key="idx" outlined class="text-body-1 px-4"
                            :value="tag" filter>{{ tag }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
              <v-row><v-divider class="mt-4 mt-md-8 mb-8"/></v-row>
              <v-row>
                <v-col md="3" cols="12" class="pl-0 pr-4 pb-0">
                  <h2 class="text--text mb-2">Comment</h2>
                  <div class="flex-row d-flex">
                    <div class="pr-1">
                      <v-icon color="accent" class="mr-1">mdi-shield-account</v-icon>
                    </div>
                    <div class="pr-4">
                      <p class="text--secondary mb-0">
                        Remember to keep your comments respectful and to follow our
                        <strong class="ml-0 ml-md-5 accent--text text-decoration-underline pointer" @click="viewTerms">
                          Terms of Use <v-icon x-small color="accent">mdi-open-in-new</v-icon>
                        </strong>
                      </p>
                    </div>
                  </div>
                </v-col>
                <v-col md="9" cols="12" class="px-0">
                  <v-textarea v-model="courseRating.comment" flat filled no-resize height="250" class="grow-0" counter
                              :error-messages="commentErrors" solo-inverted background-color="border"
                              placeholder="The course was ... (1000 character max)."/>
                </v-col>
              </v-row>
              <v-row class="mt-12">
                <v-spacer/>
                <v-btn color="accent" class="px-4 mr-4" outlined @click="resetModal">Cancel</v-btn>
                <v-btn color="accent" class="px-4" @click="submit" depressed :disabled="!enableSubmit">
                  {{ editMode ? 'Update' : 'Submit' }}
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="needAuthentication" @click:outside="store.errors.resetAuthError()" max-width="425px">
      <v-card :outlined="store.app.darkMode" style="border-radius: 5px; overflow-y: auto" height="650px">
        <SessionModal @loggedIn="userAuthenticated" css="mt-12 pt-8 px-0 overflow-hidden"/>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SessionModal from '@/components/SessionModal.vue'
import QuickStats from '@/components/QuickStats.vue'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'ReviewModal',
  components: { QuickStats, SessionModal },
  props: {
    ratingData: { type: Object, default: null },
    course: { type: Object, required: true },
    editMode: { type: Boolean, default: false },
    openModal: { type: Boolean, default: false }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    needAuthentication: false,
    reviewModal: false,
    authLock: false,
    grades: [
      '4.0 (90-100)',
      '4.0 (85-89)',
      '3.7 (80-84)',
      '3.3 (77-79)',
      '3.0 (73-76)',
      '2.7 (70-72)',
      '2.3 (67-69)',
      '2.0 (63-66)',
      '1.7 (60-62)',
      '1.3 (57-59)',
      '1.0 (53-56)',
      '0.7 (50-52)',
      '0.0 (0-49)',
      'CR/NCR'
    ],
    terms: ['F - First half', 'S - Second half', 'Y - Double semester'],
    ratingFields: [
      { label: 'understanding', text: 'Provided a deeper understanding of the subject' },
      { label: 'evaluations', text: 'Evaluations improved course understanding' },
      { label: 'environment', text: 'The professor created a good learning environment' },
      { label: 'application', text: 'Evaluations allowed me to demonstrate my knowledge' },
      { label: 'stimulating', text: 'The course was intellectually stimulating' }
    ],
    numericFields: ['recommend', 'workload', 'understanding', 'evaluations', 'environment', 'application', 'stimulating'],
    initialState: {},
    courseRating: {
      course: '',
      rating: null,
      recommend: null,
      workload: null,
      understanding: null,
      evaluations: null,
      environment: null,
      application: null,
      stimulating: null,
      year: null,
      sem: null,
      term: null,
      professor: null,
      grade: null,
      bird: 0,
      tags: [],
      comment: null
    }
  }),
  watch: {
    reviewModal: function (newValue) {
      const reviewModal = document.getElementById('reviewModal')
      if (reviewModal) reviewModal.scrollTop = 0
      if (newValue && this.store.data.ratingOptions.tags.length < 1) this.getReviewOptions()
      if (!newValue) {
        this.$emit('close')
        this.resetModal()
      }
    },
    openModal: function (newValue) {
      if (newValue) this.reviewModal = newValue
    },
    ratingData: function (newValue) {
      this.courseRating = newValue
      this.numericFields.forEach(field => { this.courseRating[field] -= 1 })
      this.courseRating.professor = newValue.professor?._id || null
      this.courseRating.term = this.terms[this.terms.findIndex(t => t.startsWith(this.courseRating.term))] || null
      this.courseRating.grade = this.grades[this.grades.findIndex(g => g.startsWith(this.courseRating.grade))] || null
      this.initialState = { ...this.courseRating }
    },
    course: function (newValue) {
      if (this.authLock) {
        this.needAuthentication = false
        if (!newValue.rated && !this.editMode) {
          this.reviewModal = !this.course.rated || this.editMode
        } else {
          this.$toast.warning('You have already rated this course!')
        }
        this.authLock = false
      }
    }
  },
  computed: {
    enableSubmit () {
      return this.store.user.userInfo?.verified &&
        this.courseRating.tags.length <= 5 &&
        this.ratingFieldsComplete &&
        this.commentErrors === '' &&
        (this.editMode ? (JSON.stringify(this.initialState) !== JSON.stringify(this.courseRating)) : true)
    },
    ratingFieldsComplete () {
      let result = true
      Object.keys(this.courseRating).slice(2, 9).forEach(field => {
        if (result && (this.courseRating[field] === null || this.courseRating[field] === undefined)) result = false
      })
      return result
    },
    commentErrors () {
      if (this.courseRating.comment && this.courseRating.comment.length > 1000) {
        return 'Comments cannot exceed 1000 characters'
      }
      return ''
    }
  },
  methods: {
    async getReviewOptions () {
      const queryObj = {
        query: 'query getReviewOptions { getReviewOptions { tags, professors }}',
        operationName: 'getReviewOptions'
      }
      return await fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(queryObj)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.store.data.ratingOptions.tags = graphQlRes.data.getReviewOptions.tags
            this.store.data.ratingOptions.professors = graphQlRes.data.getReviewOptions.professors
          }
        })
        .catch(() => {})
    },
    async submit () {
      const ok = this.editMode ? await this.updateReview() : await this.addReview()
      if (ok) this.$emit('update')
      this.resetModal()
    },
    async addReview () {
      // Save the course first if necessary
      const allowSubmit = !this.course.taken ? await this.addCourse() : true

      if (allowSubmit) {
        this.courseRating.course = this.course.code
        this.courseRating.bird = !!this.courseRating.bird
        this.numericFields.forEach(field => { this.courseRating[field] += 1 })

        const requestBody = {
          query: 'mutation addReview ($review: JSON!) { addReview (review: $review) { status, message }}',
          variables: { review: this.courseRating },
          operationName: 'addReview'
        }

        this.$gtag.event('review_add_' + this.course.code, { value: 1 })

        return await this.sendRequest(requestBody)
      } else {
        this.$toast.error('An unexpected error occurred. Please try again later.')
      }
    },
    async updateReview () {
      const reviewBody = Object.keys(this.courseRating)
        .filter(key => !['likes', 'dislikes', 'flagged', 'userID', 'course', 'importDTStamp'].includes(key))
        .reduce((obj, key) => {
          return { ...obj, [key]: this.courseRating[key] }
        }, {})
      reviewBody.bird = !!reviewBody.bird
      this.numericFields.forEach(field => { reviewBody[field] += 1 })

      const requestBody = {
        query: 'mutation updateReview ($review: ReviewUpdate!) { updateReview (review: $review) { status, message }}',
        variables: { review: reviewBody },
        operationName: 'updateReview'
      }

      this.$gtag.event('review_edit', { value: 1 })

      return await this.sendRequest(requestBody)
    },
    async sendRequest (requestBody) {
      return fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            if (graphQlRes.data[requestBody.operationName].status) {
              this.$toast.info(graphQlRes.data[requestBody.operationName].message)
            } else {
              this.$toast.warning(graphQlRes.data[requestBody.operationName].message)
            }
            return true
          } else {
            this.$toast.error(graphQlRes.errors[0].message.message)
            return false
          }
        })
        .catch(() => {
          this.$toast.error('An unexpected error occurred. Please try again later.')
          return false
        })
    },
    resetModal () {
      this.reviewModal = false
      this.courseRating = {
        course: '',
        rating: null,
        recommend: null,
        workload: null,
        understanding: null,
        evaluations: null,
        environment: null,
        application: null,
        stimulating: null,
        year: null,
        sem: null,
        term: null,
        professor: null,
        grade: null,
        bird: 0,
        tags: []
      }
    },
    async addCourse () {
      const queryObj = {
        query: 'mutation addCourse ($course: [JSON]!) { addCourse (course: $course) { status, message }}',
        variables: { course: [{ code: this.course.code, taken: true }] },
        operationName: 'addCourse'
      }
      return await fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(queryObj)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          return graphQlRes.data ? graphQlRes.data.addCourse.status : false
        })
        .catch(() => {
          return false
        })
    },
    userAuthenticated () {
      this.authLock = true
      this.$emit('update')
    },
    verifyMe () {
      this.store.app.verifyMe().then(message => this.$toast.info(message, { timeout: 10000 })).catch(err => this.$toast.error(err.message))
    },
    viewTerms () {
      window.open((window.location.origin + '/terms'), '_blank').focus()
    }
  }
}
</script>

<style scoped>
  >>>.v-expansion-panel::before {
    box-shadow: none !important;
  }
  >>>.v-expansion-panel-header > :not(.v-expansion-panel-header__icon) {
    flex: none;
  }
  .v-expansion-panel-header {
    padding: 20px 10px;
  }
  >>>.v-expansion-panel-content__wrap {
    padding: 0 10px 15px 10px;
  }
  >>>.theme--dark.v-stepper {
    background: var(--v-background-base);
  }
  >>>.v-sheet.v-stepper:not(.v-sheet--outlined) {
    box-shadow: none;
    padding-bottom: 20px;
  }
  >>>.v-select__selections {
    padding-top: 12px !important;
    padding-bottom: 6px !important;
  }
  >>>.v-select.v-input--dense .v-chip {
    margin: 4px;
  }
  >>>.theme--light.v-list-item .v-list-item__mask {
    color: #0789F2;
    background: #eee;
  }
  >>>.theme--dark.v-list-item .v-list-item__mask {
    color: #0789F2;
    background: #000000;
  }
  >>>.v-stepper__header {
    box-shadow: none;
  }
  >>>.v-stepper__content {
    padding: 10px 20px;
  }
  >>>.v-dialog--fullscreen {
    background: rgba(0,0,0,0.8);
    overflow: hidden;
  }
  .reviewCard {
    overflow-y: auto;
    border-radius: 14px; margin-top: 50px !important;
    padding: 0 35px 120px 35px !important;
  }
  .closeReview {
    position: absolute;
    justify-content: right;
    padding: 15px;
  }
  .activeRating {
    background-color: var(--v-accent-base) !important;
    color : #FFFFFF !important;
    font-weight: bold;
  }
  .v-chip--disabled {
    opacity: 0.7;
  }

</style>
