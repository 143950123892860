<template>
  <div class="d-block">
    <div class="my-3 align-center d-flex flex-row">
      <v-text-field v-model="search" dense solo-inverted placeholder="Search" flat hide-details background-color="border"/>
      <v-spacer />
      <v-btn v-if="schedules.length < 24" class="px-3 text-capitalize" color="accent" depressed @click="$router.push('/timetable')">
        <v-icon class="mr-3">mdi-calendar-plus</v-icon>New <span v-if="!store.app.onMobile">Timetable</span>
      </v-btn>
      <p v-else class="mb-0 text--secondary">
        Timetable limit of (24) reached
      </p>
    </div>

    <v-card elevation="0" outlined class="relative overflow-y-auto rounded-lg h-full">
      <v-data-table :headers="tableHeaders" :items="schedules" :search="search" :disable-sort="store.app.onMobile"
                    :dense="store.app.onMobile" :hide-default-header="store.app.onMobile"
                    :hide-default-footer=true :options="{ itemsPerPage: 24 }">
        <template v-slot:item.name="{ item }">
          <v-col v-if="store.app.onMobile" class="hover pa-2 w-full" style="line-height: 1.2">
            <h3 class="font-weight-medium courseTitle courseCode pointer" @click="goToSchedule(item._id)">{{ item.name }}
              <v-spacer/>
            </h3>
            <v-row class="ma-0 w-full align-baseline">
              <p class="text--secondary mb-0">
                <v-icon small class="mr-1">mdi-clock-edit-outline</v-icon>{{ new Date(item.updatedAt).toDateString() }}
              </p>
              <v-spacer/>
              <v-chip v-if="item.shared" color="accent" small>
                <v-icon size="14">mdi-link-variant-off</v-icon>
              </v-chip>
              <v-chip v-else color="secondary" small outlined>
                <v-icon size="14">mdi-link-variant-off</v-icon>
              </v-chip>
            </v-row>
          </v-col>
          <p v-else class="accent--text text-decoration-underline pointer mb-0 font-weight-medium text-body-2" @click="goToSchedule(item._id)">{{ item.name }}</p>
        </template>
        <template v-slot:item.shared="{ item }">
          <ShareButton v-if="item.shared" message="Timetable link copied to clipboard!" :value="item._id" page="timetable">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" color="accent" small class="pointer">
                  <v-icon size="18">mdi-link-variant</v-icon>
                </v-chip>
              </template>
              <span>Copy link</span>
            </v-tooltip>
          </ShareButton>
          <v-chip v-else color="secondary" small outlined>
            <v-icon size="18">mdi-link-variant-off</v-icon>
          </v-chip>
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ new Date(item.createdAt).toLocaleString() }}
        </template>
        <template v-slot:item.updatedAt="{ item }">
          {{ new Date(item.updatedAt).toLocaleString() }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu transition="slide-y-transition" :offset-y="true" bottom close-on-click nudge-bottom="10" right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="20" class="mobileMenu">mdi-dots-horizontal</v-icon>
            </template>
            <v-list>
              <v-list-item @click="deleteSchedule(item)">
                <v-list-item-content>
                  <p class="align-self-center mb-0 text-no-wrap text-body-2">
                    <v-icon color="error" size="20" class="mr-2">mdi-delete</v-icon>Delete timetable</p>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import ShareButton from '@/components/ShareButton.vue'
import { useAllStores } from '@/stores/useAllStores'

export default {
  components: { ShareButton },
  props: {
    showDelete: { type: Boolean },
    height: { type: Number, default: 800 }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data () {
    return {
      panel: [0],
      loading: true,
      schedules: [],
      search: '',
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Shared', value: 'shared', align: 'start d-none d-md-table-cell' },
        { text: 'Updated On', value: 'updatedAt' },
        { text: 'Created On', value: 'createdAt' },
        { text: ' ', sortable: false, value: 'actions' }
      ],
      mobileHeaders: [0]
    }
  },
  mounted () {
    this.getSchedules()
  },
  computed: {
    tableHeaders () {
      if (!this.store.app.onMobile) {
        return this.headers
      } else {
        return this.headers.filter((element, index) => this.mobileHeaders.includes(index))
      }
    }
  },
  methods: {
    async getSchedules () {
      this.loading = true
      this.schedules = await (await fetch('/rest/v1/user/mySchedules')).json()
      this.loading = false
    },
    goToSchedule (id) {
      this.$router.push({ path: 'timetable', query: { id } })
    },
    async deleteSchedule (schedule) {
      if (confirm('Are you sure you want to delete: ' + schedule.name + '?')) {
        const resp = await fetch('/rest/v1/schedule/delete/' + schedule._id, { method: 'POST' })

        if (!resp.ok) {
          this.$toast.error('Failed to delete schedule: ' + schedule.name)
        } else {
          this.$toast.info(`Deleted "${schedule.name}"`)
          this.$emit('update')
        }

        await this.getSchedules()
      }
    }
  }
}
</script>

<style scoped>
  .schedule-row {
    /* border: solid 1px grey; */
    cursor: pointer;
    width: 30%;
    position: relative;
  }
  .theme--dark.v-data-table {
    background-color: var(--background-colour) !important;
  }
  >>>.v-data-table__mobile-row__cell {
    min-width: 100% !important;
    text-align: left;
  }
</style>
