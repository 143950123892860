<template>
  <div>
    <span class="text-h6 font-weight-regular">Demographics</span>
    <v-card elevation="0" class="rounded-lg" color="background">
      <v-card-text>
        <v-row v-if="!store.app.onMobile && store.user.userInfo.data.courses.length > 0">
          <v-carousel v-model="carousel" hide-delimiters show-arrows-on-hover height="250">
            <v-carousel-item>
              <apexchart type="radar" class="chart" height="250px" :options="coursesChartOptions" :series="fosData()" />
            </v-carousel-item>
            <v-carousel-item>
              <apexchart type="radar" class="chart" height="250px" :options="breadthChartOptions" :series="brData()" />
            </v-carousel-item>
          </v-carousel>
        </v-row>

        <v-row v-if="!store.app.onMobile && store.user.userInfo.data.courses.length < 1" class="mt-2 ml-0 mb-8 font-italic text--secondary text-body-2">
          No courses... <strong class="accent--text hyperlink ml-1" @click="$router.push('/directory')"> Try adding one! </strong>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { useAllStores } from '@/stores/useAllStores'
import { deepClone, swapObjectKeyValues } from '@/utils/shared/helpers'

export default {
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    panel: [0],
    carousel: 0,
    breadthMapping: {
      'Arts, Literature and Language': 'Arts.Lit.',
      'History, Philosophy and Cultural Studies': 'Hist.P.C.',
      'Natural Sciences': 'Nat.Sci.',
      'Social and Behavioural Sciences': 'Soc.B.S.',
      'Quantitative Reasoning': 'Quant.R.',
      'Creative and Cultural Representations (1)': 'Creative.C.R (1)',
      'Thought, Belief and Behaviour (2)': 'Thought.B.B. (2)',
      'Society and its Institutions (3)': 'Society.I. (3)',
      'Living Things and Their Environment (4)': 'Living.Env. (4)',
      'The Physical and Mathematical Universes (5)': 'Physical.M.U. (5)',
      Other: 'Other'
    },
    breadthReverseMapping: { }, // Filled out in mounted()
    fieldMap: []
  }),
  mounted () {
    this.breadthReverseMapping = swapObjectKeyValues(deepClone(this.breadthMapping))
  },
  computed: {
    coursesChartOptions () {
      return {
        chart: {
          toolbar: { show: false },
          background: this.store.app.darkMode ? '#0F0F0F' : '#FFFFFF'
        },
        markers: { size: 0 },
        legend: { show: false },
        theme: { mode: this.store.app.darkMode ? 'dark' : 'light' },
        noData: { text: 'No data available', align: 'center', verticalAlign: 'middle', offsetY: -15, offsetX: 15 },
        states: { active: { filter: { type: 'none', value: 0 } } },
        colors: [this.store.app.darkMode ? '#1976D2' : '#003C85'],
        fill: { opacity: 1 },
        tooltip: { followCursor: true },
        yaxis: { labels: { formatter: (value) => { return Math.round(value) } } },
        plotOptions: {
          radar: {
            polygons: {
              fill: {
                colors: this.store.app.darkMode ? ['#1E1E1E', '#0F0F0F'] : ['#f8f8f8', '#FFFFFF']
              }
            }
          }
        }
      }
    },
    breadthChartOptions () {
      return {
        ...(this.coursesChartOptions),
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: (val, opt) => {
                const key = this.brData()[0].data[opt.dataPointIndex].x[0]
                return `${this.breadthReverseMapping[key]}:`
              }
            }
          }
        }
      }
    }
  },
  methods: {
    fosData () {
      const fields = {}
      const mgmCodes = ['MGA', 'MGF', 'MGH', 'MGI', 'MGM', 'MGO', 'MGS', 'MGT']
      this.store.user.userInfo.data.courses.forEach((course) => {
        let field = course.course.slice(0, 3)
        if (mgmCodes.includes(field)) field = 'MGT'
        this.fieldMap[field] = course.field
        if (field in fields) {
          fields[field] += 1
        } else {
          fields[field] = 1
        }
      })
      return [{ name: '# of Courses', data: Object.keys(fields).map((key) => ({ x: [key], y: fields[key] })) }]
    },
    brData () {
      const fields = {}
      this.store.user.userInfo.data.courses.forEach((course) => {
        if (course.breadth) {
          course.breadth.forEach((b) => {
            if (b in fields) {
              fields[b] += 1
            } else {
              fields[b] = 1
            }
          })
        }
      })

      return [{ name: 'Courses', data: Object.keys(fields).map((key) => ({ x: [this.breadthMapping[key === 'null' ? 'Other' : key]], y: fields[key] })) }]
    }
  }
}
</script>

<style scoped>
.chart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 250px;
}
</style>
