<template>
  <div class="d-block">
    <div class="my-3 align-center d-flex flex-row">
      <span class="font-weight-regular text-h6">My Courses</span>
      <v-spacer/>
      <AddCourses v-if="store.app.onMobile" :open-modal="enableCourseAdd" @close="addCourses = false"/>
    </div>
    <div v-if="!store.app.onMobile" class="my-3 align-center d-flex flex-row">
      <v-text-field v-model="search" id="searchBar" label="Course or Keyword" prepend-inner-icon="mdi-magnify" dense
                    single-line hide-details solo-inverted flat clearable background-color="border"/>
      <v-spacer/>
      <AddCourses :open-modal="enableCourseAdd" @close="addCourses = false"/>
    </div>
    <v-card elevation="0" outlined class="relative overflow-y-auto rounded-lg h-full">
      <v-data-table :headers="tableHeaders" :items="store.user.userInfo.data.courses" :search="search"
                    :items-per-page="tableOptions.itemsPer" :disable-sort="store.app.onMobile"
                    :loading="!store.user.userInfo.data.checked" :dense="store.app.onMobile"
                    @update:page="$scrollTo('#searchBar', { duration: 200, offset: -50 })"
                    :footer-props="footerProps" :hide-default-header="store.app.onMobile">
        <!-- Templates for custom CSS on whatever columns -->
        <template v-slot:item.course="{ item }">
          <v-col :class="store.app.onMobile ? 'hover pa-2' : 'hover pl-0'"
                 @click="$router.push({ path: '/courses', query: { c: item.code } })" style="line-height: 1.2">
            <h3 class="font-weight-medium courseTitle courseCode">{{ item.code }}
              <v-spacer/>
              <CourseToggle v-if="store.app.onMobile" style="right: 65px;" already-taken saved :course="item"
                            @courseToggle="store.data.loadUserCourses()"/>
            </h3>
            <h4 class="font-weight-regular text-body-2 courseTitle text-left">{{ item.course.slice(9) }}</h4>
          </v-col>
        </template>
        <template v-slot:item.field="{ item }">
          <p class="mb-0">{{ item.field.join(', ') }}</p>
        </template>
        <template v-slot:item.breadth="{ item }">
          <p class="mb-0">{{ item.breadth ? item.breadth.join(', ') : '' }}</p>
        </template>
        <template v-slot:item.actions="{ item }">
          <CourseToggle already-taken saved :course="item" icon="mdi-dots-horizontal" @courseToggle="store.data.loadUserCourses()"/>
        </template>
      </v-data-table>
    </v-card>
  </div>

</template>

<script>
import AddCourses from '@/components/AddCourses.vue'
import CourseToggle from '@/components/CourseToggle.vue'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'MyCourses',
  components: { CourseToggle, AddCourses },
  props: {
    addCourse: { type: Boolean, default: false }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    tableOptions: { pageOptions: [20, 30, 40, 50], itemsPer: 20 },
    prevToastID: null,
    addCourses: false,
    mobileHeaders: [0],
    search: '',
    headers: [
      { text: 'Course', value: 'course' },
      { text: 'Field of Study', value: 'field', align: 'start d-none d-md-table-cell' },
      { text: 'Breadth REQ', value: 'breadth' },
      { text: ' ', sortable: false, value: 'actions' }
    ]
  }),
  computed: {
    tableHeaders () {
      if (!this.store.app.onMobile) {
        return this.headers
      } else {
        return this.headers.filter((element, index) => this.mobileHeaders.includes(index))
      }
    },
    footerProps () {
      return {
        itemsPerPageOptions: this.tableOptions.pageOptions,
        showFirstLastPage: !this.store.app.onMobile,
        itemsPerPageText: !this.store.app.onMobile ? 'Courses per page' : ''
      }
    },
    enableCourseAdd () {
      return (this.addCourses || this.addCourse)
    }
  }
}
</script>

<style scoped>
  .theme--dark.v-data-table {
    background-color: var(--background-colour) !important;
  }
  >>>.v-data-table__mobile-row__cell {
    min-width: 100% !important;
    text-align: left;
  }

</style>
