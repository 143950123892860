<template>
  <div>
    <!-- ACCOUNT INFO - Dialog popup so users can review their account information -->
    <v-dialog persistent v-model="account.dialog" :fullscreen="store.app.onMobile" max-width="500px">
      <v-card :outlined="store.app.darkMode" style="border-radius: 5px" class="d-flex flex-column">
        <v-card-title class="pt-6 text-h5 font-weight-bold accent--text">Account Info & Settings</v-card-title>
        <v-card-text class="pb-8" @click="$refs.form.resetValidation()">
          <p class="pb-3 text--secondary">Below is all the information
            linked to your <strong>UofT Index</strong> account. You can change your display name and
            username, update your campus, or delete your account if you really want to...</p>
          <v-skeleton-loader v-if="account.loading" type="list-item-three-line, list-item-three-line"/>
          <span v-else>
            <v-row>
              <v-col cols="12" sm="4" class="py-1"><strong>Display Name</strong></v-col>
              <v-col class="py-0">
                <v-form ref="form">
                  <v-text-field v-model="account.newDisplayName" persistent-placeholder class="py-0 my-0" dense label=""
                                :placeholder="store.user.userInfo.displayName" counter="50" maxlength="50" outlined/>
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" class="py-1"><strong>Username</strong></v-col>
              <v-col class="py-0">
                <v-form ref="form">
                  <v-text-field v-model="account.newName" persistent-placeholder :placeholder="store.user.userInfo.username"
                                :label="validation.message" :rules="validation.rules" class="py-0 my-0" dense
                                counter="20" maxlength="20" outlined/>
                </v-form>
              </v-col>
            </v-row>
            <v-row class="pb-5">
              <v-col cols="12" sm="4" class="py-1"><strong>Home Campus</strong></v-col>
              <v-col class="py-0">
                <DropMenu icon="" :options="campus.options" chevron outlined secondary color="secondary"
                          :default-active="campus.active" :width="store.app.onMobile ? '100%' : ''"
                          @optionUpdate="campus.active = $event"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" class="py-0 text-capitalize"><strong>Created</strong></v-col>
              <v-col class="py-0">
                <p class="text--secondary">{{ new Date(account.info.created).toLocaleString() }}</p>
              </v-col>
            </v-row>

             <v-row>
              <v-col cols="12" sm="4" class="py-0 text-capitalize"><strong>Updated</strong></v-col>
              <v-col class="py-0">
                <p class="text--secondary">{{ new Date(account.info.updated).toLocaleString() }}</p>
              </v-col>
            </v-row>
          </span>
        </v-card-text>
        <v-spacer/>
        <v-divider/>
        <v-card-actions class="py-3">
          <v-btn color="#FF0000" text x-small class="text-decoration-underline"
                 @click="account.deleteWarning = true">Delete Account</v-btn>
          <v-spacer/>
          <v-btn color="accent" text @click="$emit('close')">Close</v-btn>
          <v-btn v-if="displayNameChanged || usernameChanged || campusChanged" color="accent" depressed
                 @click="updateProfile" class="ml-4">Save Changes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ACCOUNT DELETION - Dialog popup with account deletion warning -->
    <v-dialog persistent v-model="account.deleteWarning" :fullscreen="store.app.onMobile" max-width="500px">
      <v-card class="d-flex flex-column">
        <v-card-title class="pt-6 text-h5 error--text">Account Deletion Warning!</v-card-title>
        <v-card-text class="pb-0">
          <p class="mb-0 py-2 text--secondary">Are you sure you want to delete your account? This action cannot
            be undone and any data related to your account including:
          </p>
          <ul class="mb-2">
            <li class="text--secondary"
                :key="item"
                v-for="item in ['Username', 'Saved courses', 'Timetables', 'Ratings']"
            >
              {{ item }}
            </li>
          </ul>
          <p class="text--secondary">
            will be lost. After you delete your account, you can create another
            account with the same email provided that it is still available.
          </p>
          <p class="py-2 text-body-2 error--text font-weight-medium">To confirm, please enter the email address associated with your account.
          </p>
          <v-text-field v-model="account.emailConfirm" persistent-placeholder placeholder="Email address"
                        class="py-0 my-0" dense outlined/>
        </v-card-text>
        <v-card-actions class="py-2 justify-center">
          <v-btn :disabled="!enteredEmail" depressed :loading="account.deleting" color="#FF0000"
                 class="white--text px-12" @click="deleteAccount">Delete Account</v-btn>
        </v-card-actions>
        <v-spacer/>
        <v-divider class="mt-4"/>
        <v-card-actions class="py-2">
          <v-spacer/>
          <v-btn color="accent" text @click="account.deleteWarning = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useAllStores } from '@/stores/useAllStores'
import DropMenu from '@/components/DropMenu'
import Bugsnag from '@bugsnag/js'

export default {
  components: { DropMenu },
  props: {
    allowSave: { type: Boolean, default: false }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    account: {
      dialog: true,
      deleteWarning: false,
      initial: true,
      loading: true,
      newName: null,
      newDisplayName: '',
      emailConfirm: null,
      deleting: false,
      info: []
    },
    campus: {
      active: 0,
      options: [{ label: 'UOFT' }, { label: 'UTSC' }, { label: 'UTSG' }, { label: 'UTM' }]
    },
    validation: {
      message: '',
      rules: [
        v => !!v || 'Username cannot be empty',
        v => (v && !/[<>/'"&]/.test(v)) || 'Cannot contain: <, >, &, \' , " or /',
        v => (v && v.length >= 5) || 'Must be at least 5 characters',
        v => (v && v.length <= 20) || 'Cannot exceed 20 characters'
      ]
    }
  }),
  mounted () {
    this.getUserInfo()
  },
  computed: {
    displayNameChanged () {
      return this.account.newDisplayName && (this.account.newDisplayName !== this.store.user.userInfo.displayName)
    },
    usernameChanged () {
      return (
        this.account.newName &&
        !/[<>/'"&]/.test(this.account.newName) &&
        this.account.newName.trim().length >= 5 &&
        this.account.newName.trim().length <= 20 &&
        this.account.info.username !== this.account.newName.trim()
      )
    },
    enteredEmail () {
      return this.account.emailConfirm && Object.keys(this.account.info).length === 5 && this.account.info.email === this.account.emailConfirm.trim()
    },
    campusChanged () {
      const campus = this.campus.active ? this.campus.options[this.campus.active].label : null
      return this.store.user.userInfo.defaultCampus !== campus
    }
  },
  methods: {
    async updateProfile () {
      this.account.loading = true
      const userInfo = {}
      if (this.usernameChanged) userInfo.username = this.account.newName
      if (this.displayNameChanged) userInfo.displayName = this.account.newDisplayName
      if (this.campusChanged) userInfo.defaultCampus = this.campus.active ? this.campus.options[this.campus.active].label : null
      // Form the query object to send
      const queryObj = {
        query: 'mutation updateProfile ($userInfo: JSON!) { updateProfile (userInfo: $userInfo) { status, message } }',
        variables: { userInfo: userInfo },
        operationName: 'updateProfile'
      }

      try {
        const response = await fetch('/graphql', {
          method: 'post',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(queryObj)
        })

        const graphQlRes = await response.json()

        if (graphQlRes.data) {
          if (graphQlRes.data.updateProfile.status) {
            Object.keys(userInfo).forEach((field) => {
              this.$gtag.event('update_' + field, { value: 1 })
              this.store.user.userInfo[field] = userInfo[field]
            })
            this.$toast.info(graphQlRes.data.updateProfile.message)
            this.resetFields()
            await this.getUserInfo()
          } else {
            this.$toast.warning(graphQlRes.data.updateProfile.message)
          }
        } else {
          this.$toast.error(graphQlRes.errors[0].message.message)
        }
        this.account.loading = false
        this.$emit('close')
      } catch (e) {
        this.$toast.error('An error occurred when contacting the server. Please try again later.')
        Bugsnag.notify(e)
      } finally {
        this.account.loading = false
      }
    },
    async getUserInfo () {
      this.account.loading = true
      const q = {
        query: 'query getUserInfo { getUserInfo { username, defaultCampus, email, created, updated } }',
        operationName: 'getUserInfo'
      }

      try {
        const response = await fetch('/graphql', {
          method: 'post',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(q)
        })

        const graphQlRes = await response.json()

        if (graphQlRes.data) {
          this.account.info = graphQlRes.data.getUserInfo
          // Object.entries(graphQlRes.data.getUserInfo).forEach((info) => { this.account.info.push(info) })
          this.resetFields()
          this.account.initial = false
          this.account.loading = false
        } else {
          this.showSnack(false, false, false)
        }
      } catch (e) {
        this.showSnack(false, false, false)
        Bugsnag.notify(e)

        this.$emit('close')
      } finally {
        this.account.loading = false
      }
    },
    async deleteAccount () {
      this.account.deleting = true
      const q = {
        query: 'mutation deleteAccount ($email: String!) { deleteAccount (email: $email) { status, message } }',
        variables: { email: this.account.info.email },
        operationName: 'deleteAccount'
      }

      try {
        const response = await fetch('/graphql', {
          method: 'post',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(q)
        })

        const graphQlRes = await response.json()
        if (graphQlRes.data) {
          if (graphQlRes.data.deleteAccount.status) {
            this.$gtag.event('delete_account', { value: 1 })
            this.showSnack(graphQlRes.data.deleteAccount.message, 'info', 'mdi-information-outline')
            this.account.deleteWarning = false
            this.account.dialog = false
            setTimeout(() => {
              this.$router.push('/')
              this.store.user.setUserState(null)
            }, 4000)
          } else {
            this.showSnack(graphQlRes.data.deleteAccount.message, 'warning', 'mdi-alert-outline')
          }
        } else {
          this.showSnack(false, false, false)
        }
      } catch (e) {
        Bugsnag.notify(e)
        this.showSnack(false, false, false)
      }
    },
    resetFields () {
      this.account.newName = null
      this.account.newDisplayName = null
      if (this.account.info.defaultCampus) {
        this.campus.active = this.campus.options.findIndex((campus) => { return campus.label === this.account.info.defaultCampus })
      } else {
        this.campus.active = 0
      }
    },
    showSnack (message, colour, icon) {
      if (this.prevToastID) this.$toast.dismiss(this.prevToastID)

      if (!(message || colour || icon)) {
        this.prevToastID = this.$toast.error('An error occurred when contacting the server. Please try again later.')
      } else {
        this.prevToastID = this.$toast(message, { type: colour })
      }
    }
  }
}
</script>
