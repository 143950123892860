var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block"},[_c('div',{staticClass:"my-3 align-center d-flex flex-row"},[_c('v-text-field',{attrs:{"dense":"","solo-inverted":"","placeholder":"Search","flat":"","hide-details":"","background-color":"border"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(_vm.schedules.length < 24)?_c('v-btn',{staticClass:"px-3 text-capitalize",attrs:{"color":"accent","depressed":""},on:{"click":function($event){return _vm.$router.push('/timetable')}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-calendar-plus")]),_vm._v("New "),(!_vm.store.app.onMobile)?_c('span',[_vm._v("Timetable")]):_vm._e()],1):_c('p',{staticClass:"mb-0 text--secondary"},[_vm._v(" Timetable limit of (24) reached ")])],1),_c('v-card',{staticClass:"relative overflow-y-auto rounded-lg h-full",attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.schedules,"search":_vm.search,"disable-sort":_vm.store.app.onMobile,"dense":_vm.store.app.onMobile,"hide-default-header":_vm.store.app.onMobile,"hide-default-footer":true,"options":{ itemsPerPage: 24 }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(_vm.store.app.onMobile)?_c('v-col',{staticClass:"hover pa-2 w-full",staticStyle:{"line-height":"1.2"}},[_c('h3',{staticClass:"font-weight-medium courseTitle courseCode pointer",on:{"click":function($event){return _vm.goToSchedule(item._id)}}},[_vm._v(_vm._s(item.name)+" "),_c('v-spacer')],1),_c('v-row',{staticClass:"ma-0 w-full align-baseline"},[_c('p',{staticClass:"text--secondary mb-0"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-clock-edit-outline")]),_vm._v(_vm._s(new Date(item.updatedAt).toDateString())+" ")],1),_c('v-spacer'),(item.shared)?_c('v-chip',{attrs:{"color":"accent","small":""}},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-link-variant-off")])],1):_c('v-chip',{attrs:{"color":"secondary","small":"","outlined":""}},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-link-variant-off")])],1)],1)],1):_c('p',{staticClass:"accent--text text-decoration-underline pointer mb-0 font-weight-medium text-body-2",on:{"click":function($event){return _vm.goToSchedule(item._id)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.shared",fn:function(ref){
var item = ref.item;
return [(item.shared)?_c('ShareButton',{attrs:{"message":"Timetable link copied to clipboard!","value":item._id,"page":"timetable"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"pointer",attrs:{"color":"accent","small":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy link")])])],1):_c('v-chip',{attrs:{"color":"secondary","small":"","outlined":""}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-link-variant-off")])],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleString())+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.updatedAt).toLocaleString())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":true,"bottom":"","close-on-click":"","nudge-bottom":"10","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mobileMenu",attrs:{"size":"20"}},on),[_vm._v("mdi-dots-horizontal")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deleteSchedule(item)}}},[_c('v-list-item-content',[_c('p',{staticClass:"align-self-center mb-0 text-no-wrap text-body-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error","size":"20"}},[_vm._v("mdi-delete")]),_vm._v("Delete timetable")],1)])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }