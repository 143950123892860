<template>
  <v-dialog v-model="onboarding" max-width="500px" persistent>
    <v-card :outlined="store.app.darkMode" style="border-radius: 5px">
      <v-window v-model="window">
        <v-window-item v-for="(slide, idx) in slides" :key="idx">
          <v-card color="transparent" flat class="pa-5" height="370px">
            <v-row justify="center" class="my-5">
              <v-icon v-for="(icon, index) in slide.icons" :key="index" :color="icon.colour" :size="store.app.onMobile ? 50 : 100" class="px-2">
                {{ icon.icon }}</v-icon>
            </v-row>
            <v-card-title class="font-weight-bold text-h5 pb-2">
              {{ slide.title }}
              <v-tooltip v-if="idx === 2"  bottom max-width="250px" color="tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="accent" size="22" class="mx-2 mb-0">mdi-information</v-icon>
                </template>
                <span class="font-weight-medium">Your home campus is used to auto filter various data to show
                      users the most relevant information.</span>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <v-col class="pa-0">
                {{ slide.text }}
                <v-row v-if="idx === 2" class="mt-0 pa-3 align-center">
                  <h3 class="text--text font-weight-medium mb-1">Select a home campus:</h3>
                  <v-spacer/>
                  <DropMenu icon="" :options="campus.options" chevron outlined color="accent"
                            @optionUpdate="setHomeCampus($event)" :width="store.app.onMobile ? '100%' : ''"/>
                </v-row>
              </v-col>
            </v-card-text>
            <v-card-actions class="px-4" style="position: absolute; bottom: 20px">
              <span v-if="idx !== 3">
                <v-btn color="accent" depressed class="text-capitalize px-3 mr-2" @click="next">Continue</v-btn>
                <v-btn text color="accent" class="text-right" @click="closeModal(false)">Skip</v-btn>
              </span>
              <span v-else>
                <v-btn color="accent" depressed class="text-capitalize px-3" @click="closeModal(false)">Explore</v-btn>
                <v-btn color="accent" outlined class="text-capitalize px-3 ml-3" @click="closeModal(true)">Add Courses</v-btn>
              </span>
            </v-card-actions>
          </v-card>
        </v-window-item>
      </v-window>

      <v-card-actions class="justify-center pt-0">
        <v-item-group v-model="window" class="text-center" mandatory>
          <v-item v-for="n in length" :key="`btn-${n}`" v-slot="{ active, toggle }">
            <v-btn :input-value="active" icon small @click="toggle">
              <v-icon small>mdi-record</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import { useAllStores } from '@/stores/useAllStores'
import DropMenu from '@/components/DropMenu'

export default {
  name: 'OnboardingModal',
  components: { DropMenu },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    onboarding: true,
    window: 0,
    slides: [
      {
        title: 'Welcome to UofT Index',
        text: 'UofT Index was designed by students, for students. With the goal of providing all the information ' +
          'necessary to make informed course decisions.',
        icons: [{ icon: 'mdi-book-open-page-variant', colour: 'accent' }]
      },
      {
        title: 'Feature Packed',
        text: 'Among others, the main features available to explore include prerequisite trees, course reviews, ' +
          'past offerings, drop rates, and timetable information!',
        icons: [
          { icon: 'mdi-bird', colour: 'primary' },
          { icon: 'mdi-finance', colour: 'error' },
          { icon: 'mdi-star', colour: 'warning' }
        ]
      },
      {
        title: 'UTSC, UTSG, & UTM',
        text: 'View data for courses across all UofT campuses.',
        icons: [{ icon: 'mdi-map-marker-radius-outline', colour: 'accent' }]
      },
      {
        title: 'That\'s it!',
        text: 'Time to start exploring! But first, if you have already taken courses, start by adding them to your ' +
          'account now to improve your browsing experience.',
        icons: [{ icon: 'mdi-checkbox-marked-circle-outline', colour: 'accent' }]
      }
    ],
    length: 4,
    campus: {
      active: 0,
      options: [{ label: 'UTSC' }, { label: 'UTSG' }, { label: 'UTM' }]
    }
  }),
  methods: {
    next () {
      this.window = this.window + 1 === this.length ? 0 : this.window + 1
      if (this.window === 3 && !this.store.user.userInfo.defaultCampus) this.setHomeCampus(0)
    },
    closeModal (addCourses) {
      if (addCourses) this.$emit('addCourses')
      this.store.app.setWelcomeModal(false)
      this.onboarding = false
    },
    async setHomeCampus (campus) {
      const queryObj = {
        query: 'mutation updateProfile ($userInfo: JSON!) { updateProfile (userInfo: $userInfo) { status, message } }',
        variables: { userInfo: { defaultCampus: this.campus.options[campus].label } },
        operationName: 'updateProfile'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(queryObj)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            if (graphQlRes.data.updateProfile.status) {
              this.store.user.userInfo.defaultCampus = this.campus.options[campus].label
              this.$toast.info('Home campus set successfully!')
            } else {
              this.$toast.warning(graphQlRes.data.updateProfile.message)
            }
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        }).catch(() => { this.$toast.error('An error occurred when contacting the server. Please try again later.') })
    }
  }
}
</script>

<style scoped>

</style>
