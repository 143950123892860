<template>
  <v-card elevation="0" color="background">
    <v-card-title class="pa-0">
      <span class="text-h6 font-weight-regular">Achievements</span>
    </v-card-title>
    <v-card-text class="px-2 pb-0 text-left">
      <v-col class="pa-0 my-2">
        <div class="flex flex-row">
          <v-icon :color="store.user.userInfo.verified ? 'success' : ''">
            {{ store.user.userInfo.verified ? 'mdi-checkbox-marked-circle-outline' : 'mdi-checkbox-blank-circle-outline'}}
          </v-icon>
          <span class="ml-2 text--text">Verified Account</span>
        </div>
        <div class="ml-8 text-caption">Unlock features by verifying your email</div>
      </v-col>

      <v-col class="pa-0 my-2 mr-2">
        <div class="flex flex-row">
          <v-icon :color="store.user.userInfo.defaultCampus ? 'success' : ''">
            {{ store.user.userInfo.defaultCampus ? 'mdi-checkbox-marked-circle-outline' : 'mdi-checkbox-blank-circle-outline'}}
          </v-icon>
          <span class="ml-2 text--text">Set a Home Campus</span>
        </div>
        <div class="ml-8 text-caption">View courses that matter to you</div>
      </v-col>

      <v-col class="pa-0 my-2 mr-2">
        <div class="flex flex-row">
          <v-icon :color="achievements.review ? 'success' : ''">
            {{ achievements.review ? 'mdi-checkbox-marked-circle-outline' : 'mdi-checkbox-blank-circle-outline'}}
          </v-icon>
          <span class="ml-2 text--text">Review a Course</span>
        </div>
        <div class="ml-8 text-caption">Provide your insight to help others</div>
      </v-col>

      <v-col class="pa-0 my-2">
        <div class="flex flex-row">
          <v-icon :color="achievements.timetable ? 'success' : ''">
            {{ achievements.timetable ? 'mdi-checkbox-marked-circle-outline' : 'mdi-checkbox-blank-circle-outline'}}
          </v-icon>
          <span class="ml-2 text--text">Save a Timetable</span>
        </div>
        <div class="ml-8 text-caption">A great way to organize your semester</div>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import { useAllStores } from '@/stores/useAllStores'

export default {
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    achievements: {
      timetable: false,
      review: false
    }
  }),
  mounted () {
    this.getAchievements()
  },
  methods: {
    async getAchievements () {
      const q = {
        query: 'query getUserAchievements { getUserAchievements { timetable, review } }',
        operationName: 'getUserAchievements'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.achievements = graphQlRes.data.getUserAchievements
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    }
  }
}
</script>
