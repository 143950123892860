var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block"},[_c('div',{staticClass:"my-3 align-center d-flex flex-row"},[_c('span',{staticClass:"font-weight-regular text-h6"},[_vm._v("My Courses")]),_c('v-spacer'),(_vm.store.app.onMobile)?_c('AddCourses',{attrs:{"open-modal":_vm.enableCourseAdd},on:{"close":function($event){_vm.addCourses = false}}}):_vm._e()],1),(!_vm.store.app.onMobile)?_c('div',{staticClass:"my-3 align-center d-flex flex-row"},[_c('v-text-field',{attrs:{"id":"searchBar","label":"Course or Keyword","prepend-inner-icon":"mdi-magnify","dense":"","single-line":"","hide-details":"","solo-inverted":"","flat":"","clearable":"","background-color":"border"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('AddCourses',{attrs:{"open-modal":_vm.enableCourseAdd},on:{"close":function($event){_vm.addCourses = false}}})],1):_vm._e(),_c('v-card',{staticClass:"relative overflow-y-auto rounded-lg h-full",attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.store.user.userInfo.data.courses,"search":_vm.search,"items-per-page":_vm.tableOptions.itemsPer,"disable-sort":_vm.store.app.onMobile,"loading":!_vm.store.user.userInfo.data.checked,"dense":_vm.store.app.onMobile,"footer-props":_vm.footerProps,"hide-default-header":_vm.store.app.onMobile},on:{"update:page":function($event){return _vm.$scrollTo('#searchBar', { duration: 200, offset: -50 })}},scopedSlots:_vm._u([{key:"item.course",fn:function(ref){
var item = ref.item;
return [_c('v-col',{class:_vm.store.app.onMobile ? 'hover pa-2' : 'hover pl-0',staticStyle:{"line-height":"1.2"},on:{"click":function($event){return _vm.$router.push({ path: '/courses', query: { c: item.code } })}}},[_c('h3',{staticClass:"font-weight-medium courseTitle courseCode"},[_vm._v(_vm._s(item.code)+" "),_c('v-spacer'),(_vm.store.app.onMobile)?_c('CourseToggle',{staticStyle:{"right":"65px"},attrs:{"already-taken":"","saved":"","course":item},on:{"courseToggle":function($event){return _vm.store.data.loadUserCourses()}}}):_vm._e()],1),_c('h4',{staticClass:"font-weight-regular text-body-2 courseTitle text-left"},[_vm._v(_vm._s(item.course.slice(9)))])])]}},{key:"item.field",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.field.join(', ')))])]}},{key:"item.breadth",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.breadth ? item.breadth.join(', ') : ''))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('CourseToggle',{attrs:{"already-taken":"","saved":"","course":item,"icon":"mdi-dots-horizontal"},on:{"courseToggle":function($event){return _vm.store.data.loadUserCourses()}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }