<template>
  <v-container class="pa-0 mx-0" style="width: auto;">
    <v-btn class="px-2 px-sm-3 text-capitalize" color="accent" depressed @click="addCourses = !addCourses">
      <v-icon class="mr-3">mdi-book-plus</v-icon>Add Courses
    </v-btn>
    <v-dialog v-model="addCourses" :fullscreen="store.app.onMobile" max-width="1000px">
      <v-card :outlined="store.app.darkMode" style="border-radius: 5px" :loading="loading" loader-height="5">
        <v-card-title class="pt-6 pb-4 text-h5 font-weight-bold accent--text">Add courses from ACORN</v-card-title>
        <v-card-text class="pb-0">
          <v-row>
            <v-col cols="12" class="px-4 col-lg-4 col-md-6 align-content-space-between">
              <p class="font-weight-medium text-h6 text-md-h5 text-lg-h5 mb-2">Step 1</p>
              <p>Login to <a class="text-decoration-underline accent--text hover" href="https://www.acorn.utoronto.ca" target="_blank">ACORN</a>
                and click "<strong>Academic History</strong>" -> "<strong>Complete Academic History</strong>"</p>
              <v-img src="../assets/acorn.png" aspect-ratio="1" max-width="400" style="border-radius: 5px"/>
            </v-col>
            <v-col cols="12" class="px-4 col-lg-4 col-md-6">
              <p class="font-weight-medium text-h6 text-md-h5 text-lg-h5 mb-2">Step 2</p>
              <p>Select all (<strong>Cmd/Ctrl + A</strong>) and then copy to clipboard <strong>(Cmd/Ctrl + C)</strong></p>
              <v-img src="../assets/acorn-courses.png" aspect-ratio="1" max-width="400" style="border-radius: 5px"/>
            </v-col>
            <v-col cols="12" class="px-4 col-lg-4 col-md-12 align-content-space-between" :style="store.app.onMobile ? 'padding-bottom: 100px' : ''">
              <p class="font-weight-medium text-h6 text-md-h5 text-lg-h5 mb-2">
                Step 3
                <v-tooltip bottom max-width="250px" color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="accent" class="ml-1">mdi-shield-account</v-icon>
                  </template>
                  <span class="font-weight-medium">Only the course codes that appear after pasting your history are
                    sent. All other data is ignored and never leaves your device.</span>
                </v-tooltip>
              </p>
              <p>Paste your courses below and make sure all courses are listed before submitting</p>
              <v-textarea v-model="courses" :disabled="loading" outlined filled no-resize height="292" label="Paste here! (Cmd/Ctrl + V)"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider v-if="!store.app.onMobile"/>
        <v-card-actions :class="store.app.onMobile ? 'py-5 pr-5 fixedBottom' : 'py-3 px-7'">
          <v-spacer/>
          <v-btn color="accent" class="px-4 mr-2" outlined @click="addCourses = !addCourses">Cancel</v-btn>
          <v-btn color="accent" class="px-4" :disabled="!enableSubmit" @click="processCourses" depressed>
            {{ loading ? 'Processing ...' : 'Submit' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'AddCourses',
  props: {
    openModal: { type: Boolean, default: false }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    addCourses: false,
    courses: null,
    loading: false
  }),
  computed: {
    enableSubmit () {
      return this.courses ? this.courses.match(/^([A-Za-z]{3}([A-Za-z]|[0-4])\d{2}[A-Za-z]\d,?)+/g) : false
    }
  },
  watch: {
    courses: function (newValue) {
      if (newValue) this.courses = this.parseCourses(newValue)
    },
    addCourses: function (open) {
      if (open) {
        this.courses = null
      } else {
        this.$emit('close')
      }
    },
    openModal: function (newValue) {
      if (newValue) this.addCourses = true
    }
  },
  methods: {
    parseCourses (courses) {
      const courseCodes = courses.match(/[A-Za-z]{3}([A-Za-z]|[0-4])\d{2}[A-Za-z]\d/g)
      return courseCodes ? courseCodes.join(', ') : null
    },
    async processCourses () {
      this.loading = true
      const queryObj = {
        query: 'mutation addCourse ($course: [JSON]!) { addCourse (course: $course) { status, message }}',
        variables: { course: this.courses.split(', ').map(course => course.toUpperCase()) },
        operationName: 'addCourse'
      }
      return await fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(queryObj)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            if (graphQlRes.data.addCourse.status) {
              this.$toast.info(graphQlRes.data.addCourse.message)
              this.$gtag.event('uploaded_courses', { value: 1 })
            } else {
              this.$toast.warning(graphQlRes.data.addCourse.message)
            }
            this.addCourses = false
            this.store.data.loadUserCourses()
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
          this.loading = false
        })
        .catch(() => {
          this.$toast.error('An error occurred when contacting the server. Please try again later.')
        })
    }
  }
}
</script>

<style scoped>
  .v-tooltip__content {
    opacity: 1 !important;
  }
</style>
