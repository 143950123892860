<template>
  <v-card elevation="0" class="rounded-lg pb-0 pb-md-3" color="background">
    <v-row style="margin: 0 -12px">
      <v-col cols="9">
        <v-row :align="store.app.onMobile ? 'top' : 'center'" class="px-0">
          <Avatar :size="store.app.onMobile ? 55 : 95" :showBg="true" :showBorder="true" class="text-center"/>
          <div class="ml-3 ml-md-6 text-left">
            <p class="mb-0 text-h5 font-weight-medium">
              {{ store.user.userInfo.displayName || store.user.userInfo.username }}
              <v-tooltip bottom max-width="250px" color="tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-if="store.user.userInfo.verified" v-bind="attrs" v-on="on" color="accent" size="24">mdi-check-decagram</v-icon>
                  <v-icon v-else v-bind="attrs" v-on="on" color="#FB8C00" size="24">mdi-alert-decagram</v-icon>
                </template>
                <span v-if="store.user.userInfo.verified" class="font-weight-medium">Verified Account</span>
                <span v-else class="font-weight-medium">Unverified Account</span>
              </v-tooltip>
            </p>
            <p class="mb-2 subtitle-1 text--secondary">
              <v-icon small>mdi-at</v-icon>{{ store.user.userInfo.username }}
            </p>
            <p v-if="!store.app.onMobile" class="mb-0 subtitle-1 text--secondary">
              <v-icon small class="mr-1">mdi-calendar</v-icon>Joined {{ new Date().toDateString() }}
              <span>
                <v-icon small class="ml-2 mr-1">mdi-email-outline</v-icon>{{ store.user.userInfo.email }}
              </span>
            </p>
          </div>
        </v-row>
      </v-col>
      <v-col cols="3" class="text-right align-self-md-center pa-0">
        <v-btn v-if="!store.app.onMobile" @click="accountToggle(true)" color="accent" depressed>
          Edit Profile
        </v-btn>
        <v-icon v-else @click="accountToggle(true)" color="accent" size="30">mdi-cog-outline</v-icon>
      </v-col>
    </v-row>
    <ProfileEdit v-if="profileEditOpen" @close="profileEditOpen = false"  />
  </v-card>
</template>

<script>
import { useAllStores } from '@/stores/useAllStores'
import Avatar from '@/components/shared/Avatar'
import ProfileEdit from '@/components/dashboard/ProfileEdit.vue'

export default {
  components: { ProfileEdit, Avatar },
  props: { },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    profileEditOpen: false
  }),
  watch: {
    'account.deleteWarning': function (value) {
      if (value) this.account.emailConfirm = null
    }
  },
  methods: {
    accountToggle (show) {
      this.profileEditOpen = show
    }
  }
}
</script>
