<template>
  <div class="d-block">
    <div class="mt-0 align-center d-flex flex-row">
      <span class="font-weight-regular text-h6">Pinned</span>
      <v-spacer />
      <v-btn text small class="accent--text pa-1" @click="editPins = !editPins">Edit Pins</v-btn>
    </div>
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate size="64" width="6" color="accent" class="py-12 my-12"/>
    </div>
    <div v-else-if="pins.length < 1" class="text-center pb-4">
      <v-icon color="secondary" large class="ma-5">mdi-pin-off-outline</v-icon>
      <p class="text--secondary">No pinned timetables</p>
    </div>
    <div v-else class="pa-0 d-flex flex-row flex-wrap" style="margin: 0 -4px">
      <v-col v-for="(schedule, idx) in pins" :key="idx" cols="6" class="col-md-4 d-flex align-center pa-1">
        <v-card elevation="0" height="110" outlined class="w-full rounded-lg pa-3 relative">
          <v-row class="ma-0">
            <h4 class="font-weight-medium overflow-ellipsis pointer timetableName" @click="goToSchedule(schedule._id)">
              {{ schedule.name }}
            </h4>
          </v-row>
          <p class="text--secondary text-caption mb-0">
            <v-icon small>mdi-clock-edit-outline</v-icon>
            {{ new Date(schedule.updatedAt).toDateString() }}
          </p>
          <v-chip v-if="schedule.shared" color="accent" small class="mt-4" style="position: absolute; bottom: 12px">
            <v-icon class="mr-1" size="18">mdi-link-variant</v-icon>
            <span class="font-weight-bold">Shared</span>
          </v-chip>
          <v-chip v-else color="secondary" small outlined class="mt-4" style="position: absolute; bottom: 12px">
            <v-icon class="mr-1" size="18">mdi-link-variant-off</v-icon>
            <span class="font-weight-bold">Private</span>
          </v-chip>
        </v-card>
      </v-col>
    </div>
    <v-dialog v-model="editPins" max-width="450px">
      <v-card :outlined="store.app.darkMode" style="border-radius: 5px" class="ma-0">
        <v-card-title class="pt-6 text-h5 font-weight-bold accent--text">Edit Pins</v-card-title>
        <v-card-text class="pb-0">
          <p class="text--secondary mb-2">Select up to six of your timetables you would like to quickly access from your dashboard.</p>
          <v-virtual-scroll v-if="schedules.length" height="300" item-height="35" :items="schedules">
            <template v-slot:default="{ item }">
              <v-checkbox v-model="item.pinned" :disabled="numberOfPins > 5 && !item.pinned" color="accent" hide-details :ripple="false" class="pl-2 pr-4">
                <template v-slot:label>
                  <v-row class="ma-0 pl-2">
                    {{ item.name }}
                    <v-spacer/>
                    <div v-if="!store.app.onMobile">
                      <v-chip v-if="item.shared" color="accent" small>
                        <v-icon size="18">mdi-link-variant</v-icon>
                      </v-chip>
                      <v-chip v-else color="secondary" small outlined>
                        <v-icon size="18">mdi-link-variant-off</v-icon>
                      </v-chip>
                    </div>
                  </v-row>
                </template>
              </v-checkbox>
            </template>
          </v-virtual-scroll>
          <p v-else class="text-center my-12 py-12 text--secondary">Nothing to pin</p>
          <div class="my-3">
            <p :class="numberOfPins <= 6 ? 'text--secondary' : 'error--text'">
              Pinned timetables: {{ numberOfPins }} / 6
            </p>
          </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions class="py-3">
          <v-spacer/>
          <v-btn color="accent" class="px-4 mr-2" outlined @click="editPins = false">Cancel</v-btn>
          <v-btn color="accent" class="px-6" :disabled="numberOfPins > 6 || !modified" @click="savePinnedTimetables" depressed>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import Bugsnag from '@bugsnag/js'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'PinnedTimetables',
  props: {
    reload: { type: Boolean, default: false }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    loading: false,
    editPins: false,
    initialState: {},
    schedules: [],
    pins: []
  }),
  mounted () {
    this.getSchedules()
  },
  computed: {
    numberOfPins () {
      return this.schedules.filter(schedule => schedule.pinned).length
    },
    modified () {
      return this.schedules.reduce((acc, next) => {
        return acc || acc || (this.initialState[next._id] !== next.pinned)
      }, false)
    }
  },
  watch: {
    reload: function (newValue) {
      if (newValue) this.getSchedules()
    },
    editPins: function (newValue) {
      if (!newValue) {
        this.schedules.forEach(schedule => { schedule.pinned = this.initialState[schedule._id] })
      }
    }
  },
  methods: {
    async getSchedules () {
      this.loading = true
      this.schedules = await (await fetch('/rest/v1/user/mySchedules')).json()
      this.pins = this.schedules.filter(x => x.pinned)
      this.initialState = Object.assign({}, ...this.schedules.map((x) => ({ [x._id]: x.pinned })))
      this.loading = false
      this.$emit('update')
    },
    async savePinnedTimetables () {
      this.loading = true

      const requestBody = { true: [], false: [] }
      for (const schedule of this.schedules) {
        if (this.initialState[schedule._id] !== schedule.pinned) requestBody[schedule.pinned].push(schedule._id)
      }
      this.editPins = false

      try {
        let status = true
        for (const field of ['false', 'true']) {
          const resp = await fetch('/rest/v1/schedule/pin', {
            method: field === 'true' ? 'POST' : 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ schedules: requestBody[field] })
          })
          status = status && resp.ok
        }

        if (status) {
          this.$toast.info('Updated pinned timetables!')
        } else {
          this.$toast.error('Failed to update pinned timetables.')
        }
        await this.getSchedules()
      } catch (error) {
        this.$toast.error('Failed to update pinned timetables.')
        Bugsnag.notify((error))
        this.loading = false
      }
      this.$gtag.event('pin_timetable', { value: 1 })
    },
    goToSchedule (id) {
      this.$router.push({ path: 'timetable', query: { id } })
    }
  }
}
</script>

<style scoped>
  .v-card {
    margin: 1px;
  }
  .timetableName {
    line-height: 1;
    padding-bottom: 2px;
  }
  .timetableName:hover {
    line-height: 1;
    padding-bottom: 2px;
    color: #0789F2;
  }

</style>
